import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {getDashbord} from 'services/admin'
import {MdAccessibility, MdUpdate, MdLocalOffer, MdDateRange, MdWarning, MdStore} from 'react-icons/md'

// import { bugs, website, server } from "variables/general.js";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.js";


import styles from "assets/jss/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {

  const classes = useStyles();

  const [data, setData] = React.useState({})
  React.useEffect(()=> {
    getDashbord()
    .then((result) => {
      console.log(result.data)
      setData(result.data)
    })
  }, [])
  return (
    <div>
      <GridContainer>
        {/* <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Used Space</p>
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <MdWarning />
                </Danger>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Get more space
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <MdStore />
              </CardIcon>
              <p className={classes.cardCategory}>Medecins</p>
              <h3 className={classes.cardTitle}>{data.medecins}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdUpdate />
                Depuis le lancement
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Patients</p>
              <h3 className={classes.cardTitle}>{data.patients}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdUpdate />
                Depuis le lancement
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <MdAccessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Consultations</p>
              <h3 className={classes.cardTitle}>{data.consultations}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdUpdate />
                Depuis le lancement
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
