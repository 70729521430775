import * as React from "react";

function SvgMoney(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="#000"
      viewBox="0 0 407.534 407.534"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M406.962 226.838l-.002-.005-76.944-193.2a8 8 0 00-10.391-4.476l-.009.004L7.464 153.457a7.84 7.84 0 00-3.2 2.544A7.91 7.91 0 000 162.945v208a8 8 0 008 8h336a8 8 0 008-8v-113.6l50.496-20.112a8 8 0 004.466-10.395zM336 362.945H16v-192h320v192zm-202.52-207.96l133.952-53.376a63.648 63.648 0 0045.864 19.776l13.36 33.6H133.48zM352 240.161v-77.216a8 8 0 00-8-8h-.128l-16.952-42.576a6.623 6.623 0 00-.656-1.008 7.904 7.904 0 00-8.36-6.16 47.84 47.84 0 01-41.944-18.064 7.929 7.929 0 00-10.264-1.792 8.99 8.99 0 00-1.128.208l-163.536 65.12a7.903 7.903 0 00-4.392 4.272H46.976l271.136-107.96 71.032 178.36L352 240.161z" />
      <path d="M37.712 308.937a47.834 47.834 0 0132.296 32.256 8 8 0 007.664 5.712 7.328 7.328 0 001.232-.184c.359.102.726.177 1.096.224h192c.33-.043.656-.11.976-.2a7.449 7.449 0 008.998-5.476l.01-.044a47.83 47.83 0 0132.296-32.296 7.897 7.897 0 005.488-8.8 8.07 8.07 0 00.232-1.184v-64a7.223 7.223 0 00-.24-1.184 7.887 7.887 0 00-5.488-8.8 47.784 47.784 0 01-32.28-32.304 7.904 7.904 0 00-8.864-5.488 7.605 7.605 0 00-1.128-.224H80a7.558 7.558 0 00-1.192.24 7.904 7.904 0 00-8.8 5.488 47.832 47.832 0 01-32.296 32.288 7.897 7.897 0 00-5.488 8.8 8.076 8.076 0 00-.224 1.184v64c.046.376.121.748.224 1.112a7.92 7.92 0 005.488 8.88zM48 238.193a63.481 63.481 0 0035.248-35.248h185.496A63.498 63.498 0 00304 238.193v57.504a63.554 63.554 0 00-35.248 35.248H83.248A63.554 63.554 0 0048 295.697v-57.504z" />
      <path d="M176 314.945c26.51 0 48-21.49 48-48s-21.49-48-48-48-48 21.49-48 48c.026 26.499 21.501 47.974 48 48zm0-80c17.673 0 32 14.327 32 32s-14.327 32-32 32-32-14.327-32-32 14.327-32 32-32zM88 278.945c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12c0 6.628 5.373 12 12 12zm0-16a4 4 0 110 8 4 4 0 010-8zM264 278.945c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12c0 6.628 5.373 12 12 12zm0-16a4 4 0 110 8 4 4 0 010-8z" />
    </svg>
  );
}

export default SvgMoney;
