const LocalConfig = {
    BACKEND_URL : "http://localhost:4400/",
}
const prodConfig = {
    BACKEND_URL : "https://api.docminutes.com/",
}
const testConfig = {
    BACKEND_URL : "https://docminutes.com:5000/",
}

export default prodConfig